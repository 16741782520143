// Copyright 2019, White Label Communications, LLC, All rights reserved.

import React from 'react';
import { NavLink } from 'react-router-dom';
import userIcon from '../../images/user.svg';
import SettingsIcon from '../../images/settings.svg';
import IntegrationsIcon from '../../images/integrations.svg';
// import BillingIcon from '../../images/money.svg';
// import FiliesIcon from '../../images/file.svg';

export default class DashBoardSidebar extends React.PureComponent {
  render() {
    return (
      <div
        className="sidebar"
        id="sidebar"
      >
        <nav className="nav" role="navigation">
          <ul className="nav__list">
            <li>
              <input id="group-1" type="radio" name="menu-item" hidden />
              <label htmlFor="group-1">
                <div className="icon">
                  <img src={userIcon} alt="boomea" />
                </div>
                <div className="descr">Users</div>
              </label>
              <ul className="group-list" style={window.location.href.indexOf('/users/') > 0 ? { maxHeight: '1000px' } : {}}>
                <li>
                  <NavLink activeClassName="active" to="/users/users">
                    <div className="descr"> Manage Users</div>
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName="active" to="/users/company_directory">
                    <div className="descr"> Company Directory</div>
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <input id="group-2" type="radio" name="menu-item" hidden />
              <label htmlFor="group-2">
                <div className="icon">
                  <img src={SettingsIcon} alt="boomea" />
                </div>
                <div className="descr">Team Settings</div>
              </label>
              <ul
                className="group-list"
                style={window.location.href.indexOf('/team_settings/') > 0 ? { maxHeight: '1000px' } : {}}
              >
                <li>
                  <NavLink activeClassName="active" to="/dashboard/team_settings/file_retention">
                    <div className="descr"> File Retention</div>
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName="active" to="/dashboard/team_settings/message_retention">
                    <div className="descr"> Message Retention</div>
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName="active" to="/dashboard/team_settings/meeting_retention">
                    <div className="descr"> Meeting Retention</div>
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName="active" to="/dashboard/team_settings/tasks_retention">
                    <div className="descr"> Tasks Retention</div>
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName="active" to="/dashboard/team_settings/notes_retention">
                    <div className="descr"> Notes Retention</div>
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName="active" to="/dashboard/team_settings/voice_retention">
                    <div className="descr"> Voice Retention</div>
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName="active" to="/dashboard/team_settings/default_channels">
                    <div className="descr"> Default Channels</div>
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <input id="group-3" type="radio" name="menu-item" hidden />
              <label htmlFor="group-3">
                <div className="icon">
                  <img src={userIcon} alt="boomea" />
                </div>
                <div className="descr">Sms</div>
              </label>
              <ul className="group-list" style={window.location.href.indexOf('/sms/') > 0 ? { maxHeight: '1000px' } : {}}>
                <li>
                  <NavLink activeClassName="active" to="/sms/manage_sms">
                    <div className="descr"> Manage Sms</div>
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink activeClassName="active" to="/dashboard/integrations">
                <div className="icon">
                  <img src={IntegrationsIcon} alt="boomea" />
                </div>
                <div className="descr"> Integrations</div>
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}

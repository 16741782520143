// Copyright 2019, White Label Communications, LLC, All rights reserved.

import { combineReducers } from 'redux';
import list from './List';
import post from './Post';
import forceLogout from './ForceLogout';
import status from './Status';
import sendPasswordreset from './SendPasswordreset';

export default combineReducers({
  list,
  post,
  forceLogout,
  status,
  sendPasswordreset,
});

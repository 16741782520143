// Copyright 2019, White Label Communications, LLC, All rights reserved.

import PropTypes from 'prop-types';
import React from 'react';
import Alert from 'react-bootstrap/Alert';

const ErrorAlert = (props) => {
  const {
    error,
    onAlertDismissed,
    alertType,
    errorLink,
    handleErrorLinkClick,
  } = props;
  if (!error) {
    return null;
  }

  return (
    <Alert variant="danger" onClose={() => onAlertDismissed(alertType)} isOpen = {setInterval(()=>{
        onAlertDismissed(alertType)
      },4000)} dismissible>
      <Alert.Heading>An Error Occurred!</Alert.Heading>
      <p>
        {error}
        {' '}
        {errorLink
          ? <Alert.Link onClick={() => handleErrorLinkClick()}>{errorLink}</Alert.Link>
          : null}
      </p>
    </Alert>
  );
};

ErrorAlert.propTypes = {
  handleErrorLinkClick: PropTypes.func,
  error: PropTypes.string,
  onAlertDismissed: PropTypes.func.isRequired,
  alertType: PropTypes.string.isRequired,
  errorLink: PropTypes.string,
};

ErrorAlert.defaultProps = {
  handleErrorLinkClick: () => {},
  error: '',
  errorLink: '',
};

export default ErrorAlert;

// Copyright 2019, White Label Communications, LLC, All rights reserved.

import React from 'react';
import Layout from 'components/Layout/Layout';
import DashBoard from 'components/Layout/Dashboard';
import userIcon from 'images/user.svg';

export default function PageHome() {
  // need to convert classes to classes specific to home page
  return (
    <Layout>
      <DashBoard>
        <div className="manage-users" id="manage-users">
          <div className="title-section">
            <h2>
              <img src={userIcon} alt="boomea" />
              {' '}
              Boomea Admin Portal
            </h2>
          </div>
        </div>
      </DashBoard>
    </Layout>
  );
}

// Copyright 2019, White Label Communications, LLC, All rights reserved.

import React from 'react';
import PropTypes from 'prop-types';
import { Waypoint } from 'react-waypoint';
import CSVReader from 'react-csv-reader';
import { CSVLink } from 'react-csv';
import {
  Container, Row, Col, Table, Modal, Button, Form, OverlayTrigger, Tooltip,
} from 'react-bootstrap';
import { imageURLForUser } from 'components/Common/Utils';
import Downoad from 'images/download.svg';
import Upload from 'images/upload.svg';
import Layout from 'components/Layout/Layout';
import DashBoard from 'components/Layout/Dashboard';
import userIcon from 'images/user.svg';
import plusIcon from 'images/plus.svg';
import FormError from 'components/Common/FormError';
import ConfirmModal from 'components/Common/ConfirmModal';
import SuccessAlert from 'components/Common/Alerts/Success';
import ErrorAlert from 'components/Common/Alerts/Error';
import Avatar from 'images/avatar.svg';
import CompanyDirectoryItem from './components/CompanyDirectoryItem';


const papaparseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  transformHeader: (header) => (header
    .toLowerCase()
    .replace(/\W/g, '_')
  ),
};

class CompanyDirectory extends React.Component {
  constructor(props) {
    super(props);
    const { compDirCurrentList } = this.props;
    this.fileUpload = React.createRef();
    this.compDirCsvRef = React.createRef();
    this.state = {
      show: false, // add/edit form modal popup hide show based on this value
      newCompDirEntry: this.initializeCompDirAddForm(), // method to initialize add/edit form since have to reset form on every successful request
      isEditForm: false, // since single form used for add and edit operation
      editRecordId: null, // temp store record Id to be used in edit record request
      searchkey: compDirCurrentList.given_search ? compDirCurrentList.given_search : '',
      showSuccessAlert: false,
      showErrorAlert: false,
      confirmModalData: null,
      showConfirmModal: false,
      successAlert: '',
      errorAlert: { message: '', link: '' },
      csvHeaders: [
        { label: 'Id', key: 'id' },
        { label: 'Name', key: 'name' },
        { label: 'Phone Number', key: 'phone_number' },
        { label: 'Sms', key: 'sms' },
        { label: 'Email', key: 'email' },
      ],
      csvData: [],
    };
  }

  componentDidMount() {
    // console.log('CompanyDirectory, componentDidMount');
    const { searchkey } = this.state;
    this.getCompanyDirectoryList(searchkey, null);
    this.typingTimer = 0;
  }

  componentDidUpdate(prevProps) {
    const {
      compDirPost,
      compDirPut,
      compDirDelete,
      compDirPostMulti,
    } = this.props;
    if (prevProps.compDirPostMulti.status === 1 && compDirPostMulti.status === 3) {
      if (compDirPostMulti.error.length === 0) {
        this.setAlerts('CSV uploaded successfully', '', '');
      } else if (compDirPostMulti.error.length > 0) {
        this.generateErrorCsv(compDirPostMulti);
      }
    }
    if (prevProps.compDirPostMulti.status === 1 && compDirPostMulti.status === 2) {
      this.setAlerts('', compDirPostMulti.error, '');
    }
    if (prevProps.compDirPost.status === 1 && compDirPost.status === 2) {
      this.setAlerts('', compDirPost.error, '');
    }
    if (prevProps.compDirPost.status === 1 && compDirPost.status === 3) {
      this.setAlerts('Added Successfully', '', '');
    }
    if (prevProps.compDirPut.status === 1 && compDirPut.status === 2) {
      this.setAlerts('', compDirPut.error, '');
    }
    if (prevProps.compDirPut.status === 1 && compDirPut.status === 3) {
      this.setAlerts('Saved Successfully', '', '');
    }
    if (prevProps.compDirDelete.status === 1 && compDirDelete.status === 2) {
      this.setAlerts('', compDirDelete.error, '');
    }
    if (prevProps.compDirDelete.status === 1 && compDirDelete.status === 3) {
      this.setAlerts('Deleted Successfully', '', '');
    }
  }

  generateErrorCsv = (compDirPostMulti) => {
    this.setState({
      csvData: compDirPostMulti.error,
      csvHeaders: [
        { label: 'Id', key: 'id' },
        { label: 'Name', key: 'name' },
        { label: 'Phone Number', key: 'phone_number' },
        { label: 'Sms', key: 'sms' },
        { label: 'Email', key: 'email' },
        { label: 'Error', key: 'error' },
      ],
    }, () => {
      this.setAlerts('', 'Some of records were not added', 'Click to download Error Csv');
    });
  }

  setAlerts = (successAlert, errorAlertMessage, errorAlertLink) => {
    this.setState({
      successAlert,
      errorAlert: {
        message: errorAlertMessage,
        link: errorAlertLink,
      },
    });
  }

  onAlertDismissed = (alertType) => {
    if (alertType === 'errorAlert') {
      this.setState({
        errorAlert: {
          message: '',
          link: '',
        },
      });
    } else {
      this.setState({
        [alertType]: '',
      });
    }
  }

  initializeCompDirAddForm = () => ({
    name: '',
    email: '',
    sms: '',
    phone_number: '',
  })

  getCompanyDirectoryList = (search, lastKey) => {
    const {
      getCompanyDirectory,
      currentTeam,
    } = this.props;
    getCompanyDirectory(currentTeam.currentTeamId, search, lastKey); // no search no last_key for now
  }

  companyDirectoryUser = () => {
    this.setState({
      show: true,
    });
  }

  handleInputField = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      ...prevState,
      newCompDirEntry: {
        ...prevState.newCompDirEntry,
        [name]: value,
      },
    }));
  }

  handleClose = () => {
    this.setState({
      show: false,
    }, () => {
      this.setState({
        isEditForm: false,
        newCompDirEntry: this.initializeCompDirAddForm(),
      });
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      successAlert: '',
      errorAlert: '',
    });
    const {
      postCompanyDirectory,
      currentTeam,
      putCompanyDirectory,
    } = this.props;
    const { newCompDirEntry, isEditForm, editRecordId } = this.state;
    if (isEditForm) {
      const editFormData = {
        ...newCompDirEntry,
        id: editRecordId,
      }; // add id to request in case of put request
      putCompanyDirectory(currentTeam.currentTeamId, [editFormData]).then(
        this.setState({
          show: false,
          newCompDirEntry: this.initializeCompDirAddForm(),
          isEditForm: false,
        }), // re-initialize the add form once request
      );
    } else {
      postCompanyDirectory(currentTeam.currentTeamId, [newCompDirEntry]).then(
        this.setState({
          show: false,
          newCompDirEntry: this.initializeCompDirAddForm(),
        }), // re-initialize the add form once request
      );
    }
  }

  handleEdit = (recordId) => {
    this.setState({
      successAlert: '',
      errorAlert: {
        message: '',
        link: '',
      },
    });
    const {
      compDirCompleteList,
    } = this.props;
    this.setState({
      newCompDirEntry: {
        name: compDirCompleteList[recordId].name,
        email: compDirCompleteList[recordId].email,
        sms: compDirCompleteList[recordId].sms,
        phone_number: compDirCompleteList[recordId].phone_number,
      },
    }, () => { this.setState({ show: true, isEditForm: true, editRecordId: recordId }); }); //  to handle edit set state with record values and then show modal popup
  }

  handleDelete = (recordId) => {
    this.setState({
      successAlert: '',
      errorAlert: {
        message: '',
        link: '',
      },
    });
    const {
      deleteCompanyDirectory,
      currentTeam,
    } = this.props;
    deleteCompanyDirectory(currentTeam.currentTeamId, recordId);
    this.setState({
      showConfirmModal: false,
      confirmModalData: null,
    });
  }

  showConfirmModal = (recordId) => {
    this.setState({
      showConfirmModal: true,
      confirmModalData: recordId,
    });
  }

  cancelConfirmModal = () => {
    this.setState({
      showConfirmModal: false,
      confirmModalData: null,
    });
  }

  handleSearchInput = (e) => {
    const { value } = e.target;
    this.setState({ searchkey: value });
  }

  handleSearchSubmit = (e) => {
    e.preventDefault();
    this.searchComDirectory();
  }

  searchComDirectory = () => {
    const { searchkey } = this.state;
    this.getCompanyDirectoryList(searchkey, null);
  }

  resetSearchForm = () => {
    this.setState({ searchkey: '' });
    this.getCompanyDirectoryList('', null);
    clearTimeout(this.typingTimer);
    this.typingTimer = 0; // since user cleared search field make new api call and set timer to 0, to avoid double request
  }

  handleSearchKeyUp = (e) => {
    const { searchkey } = this.state;
    if (searchkey.length > 0 && e.keyCode !== 13) { // check if search key entered and last key by user is not enter key then only make a search
      clearTimeout(this.typingTimer);
      this.typingTimer = 0;
      this.typingTimer = setTimeout(() => {
        this.searchComDirectory();
      }, 1000);
    }
  }

  handleKeyDown = () => {
    // do nothing, just to avoid lint error of event key handle requires
  }

  handleWaypointEnter = () => {
    const { searchkey } = this.state;
    const { compDirCurrentList } = this.props;
    this.getCompanyDirectoryList(searchkey, compDirCurrentList.lastKey);
  }

  handleForce = (data) => {
    const {
      currentTeam,
      postMultiCompanyDirectory,
    } = this.props;
    if (Array.isArray(data)) {
      const finalData = data.map((record) => ({
        id: record.id,
        sms: record.sms ? String(record.sms) : '',
        phone_number: record.phone_number ? String(record.phone_number) : '',
        email: record.email ? String(record.email) : '',
        name: record.name ? String(record.name) : '',
      }));
      postMultiCompanyDirectory(
        currentTeam.currentTeamId,
        finalData,
      );
      // console.log('handleForce: ', data);
    } else {
      this.handleDarkSideForce('Provided input seems Invalid. Please check format by downloading comp dir csv');
    }
  }

  handleDarkSideForce = (error) => {
    this.setState({
      errorAlert: {
        message: error || 'An error occurred on csv upload',
        link: '',
      },
      successAlert: '',
    });
  }

  triggerCsvReader = () => {
    document.getElementById('ObiWan').click();
  }

  downloadErrorCsv = () => {
    this.forceUpdate(); // render method was not calling automatically after setState so I had to forceUpdate
    this.compDirCsvRef.current.link.click(); // csvlink async method dont work so I have used two buttons and onclick of first button call api , then call csvlink click
    // console.log('downloadErrorCsv');
    this.onAlertDismissed('errorAlert');
  }

  getCompDirCsvData = async () => {
    const {
      searchkey,
    } = this.state;
    const {
      getCompDirCsv,
      currentTeam,
    } = this.props;
    const response = await getCompDirCsv(currentTeam.currentTeamId, searchkey);
    this.setState({
      csvData: response.data,
      csvHeaders: [
        { label: 'Id', key: 'id' },
        { label: 'Name', key: 'name' },
        { label: 'Phone Number', key: 'phone_number' },
        { label: 'Sms', key: 'sms' },
        { label: 'Email', key: 'email' },
      ],
    }, () => {
      this.forceUpdate(); // render method was not calling automatically after setState so I had to forceUpdate
      this.compDirCsvRef.current.link.click(); // csvlink async method dont work so I have used two buttons and onclick of first button call api , then call csvlink click
    });
  }

  render() {
    const {
      show,
      newCompDirEntry,
      isEditForm,
      searchkey,
      showConfirmModal,
      confirmModalData,
      errorAlert,
      successAlert,
      csvData,
      csvHeaders,
    } = this.state;
    const { compDirCurrentList, compDirCompleteList, compDirPost } = this.props;
    let compDirWaypoint = null;
    if (compDirCurrentList.lastKey) {
      compDirWaypoint = (
        <div>
          <Waypoint
            onEnter={() => { this.handleWaypointEnter(this); }}
            threshold={4.0}
          />
        </div>
      );
    }
    return (
      <Layout>
        <DashBoard>
          <>
            {compDirCurrentList && compDirCurrentList.state === 2 ? <FormError error={compDirCurrentList.error} /> : null }
            {compDirPost && compDirPost.state === 2 ? <FormError error={compDirPost.error} /> : null }
            <div className="manage-users" id="manage-users">
              <div className="title-section">
                <Container fluid>
                  <Row>
                    <Col md={{ span: 7, offset: 5 }}>
                      <ul className="list-inline">
                        <li>
                          <CSVReader
                            cssClass="csv-reader-input"
                            onFileLoaded={this.handleForce}
                            onError={this.handleDarkSideForce}
                            parserOptions={papaparseOptions}
                            inputId="ObiWan"
                            inputStyle={{ display: 'none' }}
                          />
                        </li>
                        <li>
                          <OverlayTrigger
                            placement="top"
                            overlay={(
                              <Tooltip id="download">
                                Upload CompDir CSV
                              </Tooltip>
                            )}
                          >
                            <Button
                              variant="default download-button"
                              onClick={() => { this.triggerCsvReader(); }}
                            >
                              <img src={Upload} alt="boomea" />
                            </Button>
                          </OverlayTrigger>
                        </li>
                        <li>
                          <OverlayTrigger
                            placement="top"
                            overlay={(
                              <Tooltip id="download_comp_dir">
                                Download CompDir list
                              </Tooltip>
                            )}
                          >
                            <Button
                              variant="default download-button"
                              onClick={() => { this.getCompDirCsvData(); }}
                            >
                              <img src={Downoad} alt="boomea" />
                            </Button>
                          </OverlayTrigger>
                        </li>
                        <li style={{ display: 'none' }}>
                          <CSVLink
                            data={csvData}
                            headers={csvHeaders}
                            filename="boomea-compdir.csv"
                            ref={this.compDirCsvRef}
                          />
                        </li>
                        <li>
                          <Button variant="green" className="invite-people" onClick={this.companyDirectoryUser}>
                            <img src={plusIcon} width="14" alt="boomea" />
                            {' '}
                            Add Entry
                            {' '}
                          </Button>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </Container>
                <h2>
                  <img src={userIcon} alt="boomea" />
                  {' '}
                  Company Directory
                </h2>
              </div>
              <div className="manage-users-table">
                <div className="manage-area">
                  <ErrorAlert
                    error={errorAlert.message}
                    errorLink={errorAlert.link}
                    onAlertDismissed={this.onAlertDismissed}
                    alertType="errorAlert"
                    handleErrorLinkClick={this.downloadErrorCsv}
                  />
                  <SuccessAlert
                    success={successAlert}
                    onAlertDismissed={this.onAlertDismissed}
                    alertType="successAlert"
                  />
                  <Container fluid>
                    <Row>
                      <Col md={12}>
                        <div className="search-area-form">
                          <div className="serach-input">
                            <Form onSubmit={this.handleSearchSubmit}>
                              <input
                                type="text"
                                className="form-control"
                                id="search-comp-dir"
                                placeholder="search"
                                value={searchkey}
                                onChange={this.handleSearchInput}
                                onKeyUp={this.handleSearchKeyUp}
                              />
                              {searchkey
                                ? (
                                  <i
                                    onClick={this.resetSearchForm}
                                    id="searchclear"
                                    role="link"
                                    onKeyDown={this.handleKeyDown}
                                    tabIndex={0}
                                    className="clearable__clear"
                                  >
                                    ×
                                  </i>
                                )
                                : (null)}
                            </Form>
                          </div>
                        </div>
                        <div className="scroll-area">
                          <div className="table-area">
                            <Table>
                              <thead>
                                <tr>
                                  <th className="text-left">Name</th>
                                  <th>Phone Number</th>
                                  <th>SMS Number</th>
                                  <th>Email Address</th>
                                  <th className="text-center">ACTIONS</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  (compDirCurrentList.records).map((id) => (
                                    <CompanyDirectoryItem
                                      phoneNumber={compDirCompleteList[id].phone_number}
                                      smsNumber={compDirCompleteList[id].sms}
                                      key={id}
                                      id={id}
                                      name={compDirCompleteList[id].name}
                                      email={compDirCompleteList[id].email}
                                      handleEdit={this.handleEdit}
                                      handleDelete={this.showConfirmModal}
                                      imgSrc={
                                        (compDirCompleteList[id].user_id !== ''
                                          ? imageURLForUser({
                                            id: compDirCompleteList[id].user_id,
                                            last_picture_update: compDirCompleteList[id].last_picture_update,
                                          })
                                          : Avatar
                                        )
                                      }
                                    />
                                  ))
                                }
                                {compDirCurrentList.state === 2 ? 'Loading...' : null}
                              </tbody>
                            </Table>
                          </div>
                          {compDirWaypoint}
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </div>
            <Modal show={show} className="add-cduser" animation>
              <Form onSubmit={this.handleSubmit}>
                <Modal.Header>
                  <Modal.Title>{isEditForm ? 'UPDATE DIRECTORY ENTRY' : 'ADD DIRECTORY ENTRY'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form.Group controlId="cduser_Name">
                    <Form.Label>
                      <span>*</span>
                      Name:
                      {' '}
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      name="name"
                      value={newCompDirEntry.name}
                      onChange={this.handleInputField}
                    />
                  </Form.Group>
                  <Form.Group controlId="cduser_Phone_Number">
                    <Form.Label>
                      Phone Number:
                      {' '}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="phone_number"
                      value={newCompDirEntry.phone_number}
                      onChange={this.handleInputField}
                    />
                  </Form.Group>
                  <Form.Group controlId="cduser_SMS_Number">
                    <Form.Label>
                      SMS Number:
                      {' '}
                    </Form.Label>
                    <Form.Control type="text" name="sms" value={newCompDirEntry.sms} onChange={this.handleInputField} />
                  </Form.Group>
                  <Form.Group controlId="cduser_Email">
                    <Form.Label>
                      Email:
                      {' '}
                    </Form.Label>
                    <Form.Control type="email" name="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" value={newCompDirEntry.email} onChange={this.handleInputField} />
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  <Button type="button" variant="secondary" onClick={this.handleClose}>
                    Cancel
                  </Button>
                  <Button type="submit" variant="blue">
                    {isEditForm ? 'Save' : 'Add'}
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal>
          </>
        </DashBoard>
        <ConfirmModal
          show={showConfirmModal}
          data={confirmModalData}
          confirmModalMessage="Are you sure you want to delete this record?"
          handleCancel={this.cancelConfirmModal}
          handleConfirm={this.handleDelete}
        />
      </Layout>
    );
  }
}

CompanyDirectory.propTypes = {
  getCompDirCsv: PropTypes.func.isRequired,
  getCompanyDirectory: PropTypes.func.isRequired,
  postCompanyDirectory: PropTypes.func.isRequired,
  deleteCompanyDirectory: PropTypes.func.isRequired,
  putCompanyDirectory: PropTypes.func.isRequired,
  postMultiCompanyDirectory: PropTypes.func.isRequired,
  currentTeam: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  ),
  compDirCompleteList: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  ),
  compDirCurrentList: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
  ),
  compDirPost: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  ),
  compDirPut: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  ).isRequired,
  compDirDelete: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  ).isRequired,
  compDirPostMulti: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  ).isRequired,
};

CompanyDirectory.defaultProps = {
  currentTeam: '',
  compDirCompleteList: {},
  compDirCurrentList: {},
  compDirPost: {},
};

export default CompanyDirectory;

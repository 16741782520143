// Copyright 2019, White Label Communications, LLC, All rights reserved.

import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import DashBoard from 'components/Layout/Dashboard';
import BoomeaLogo from '../../images/boomea-logo.svg';
import Warning from '../../images/warning.svg';
import Loading from '../../images/refresh.svg';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    // using local state for form fields, not redux state
    this.state = {
      username: '',
      password: '',
    };
  }

  componentDidUpdate(prevProps) {
    const { login, history } = this.props;
    if (prevProps.login.status === 1 && login.status === 3) {
      history.push({
        pathname: '/users/users',
        state: { showSwitchTeamModal: true },
      });
    }
  }

  indirectlySetState = (name, value) => {
    this.setState({
      [name]: value,
    });
  }

  // need this to keep form synced with react comp state
  handleInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox'
      ? target.checked
      : target.value;
    const { name } = target;
    this.setState({ [name]: value });
  }

  // handle form submit
  handleSubmit(event) {
    const { username, password } = this.state;
    const { fetchLogin } = this.props;
    fetchLogin(username, password);
    event.preventDefault();
  }

  render() {
    const { login, location } = this.props;
    const {
      username,
      password,
    } = this.state;
    const permissionError = { pathname: '/permission_error' };
    const { from } = location.state || { from: { pathname: '/users/users', state: { showSwitchTeamModal: false } } };
    if (login.status === 2 && login.error === 'permission_error') {
      // specific case of permission, redirect user to 403 permission page
      return <Redirect to={permissionError} />;
    }
    if (login.status === 3 && login.teamModalShown) {
      return <Redirect to={from} />;
    }
    // const bodyContent = {
    //   paddingLeft: '16px',
    // };
    return (
      <DashBoard>
        <>
          <div className="login--screen">
            <div className="boomea-logo">
              <img src={BoomeaLogo} alt="boomea" />
            </div>
            <div className="login-form-container">
              <div className="widget__title">
                <h2>Login</h2>
              </div>
              <form onSubmit={this.handleSubmit} className="trasperant__bg">

                {login.status === 2 && <div className='error'><img src={Warning} alt='login-alert' /> {login.error} Your password is incorrect</div>}
                {login.status === 3 && (
                  <h2>
                    Welcome
                    {login.first}
                    <br />
                    You are already logged in.
                  </h2>
                )}

                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email address"
                    id="username"
                    name="username"
                    aria-describedby="emailHelp"
                    value={username}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    name="password"
                    placeholder="Password"
                    value={password}
                    onChange={this.handleInputChange}
                  />
                </div>
                <button type="submit" className="btn btn-primary">{login.status === 1 && <img src={Loading} alt='Loading' />} Submit</button>
                <a href="/#" className="forgot-password">Forgot Password</a>
              </form>

            </div>
          </div>
        </>
      </DashBoard>
    );
  }
}

Login.propTypes = {
  login: PropTypes.oneOfType([PropTypes.object]).isRequired,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
  fetchLogin: PropTypes.func.isRequired,
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default Login;

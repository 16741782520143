// Copyright 2019, White Label Communications, LLC, All rights reserved.

import PrimaryOwnerIcon from 'images/boomea-blue.svg';
import WorkspaceAmdinIcon from 'images/boomea-green.svg';
import MemberIcon from 'images/boomea-yellow.svg';
import GuestIcon from 'images/boomea-red.svg';
import Zendesk from 'images/zendesk.png';

export function imageURLForUser(userObject) {
  return `${process.env.REACT_APP_API_URL}/api/v4/users/${userObject.id}/image?_=${userObject.last_picture_update}`;
}

export function getUserType(user) {
  let userType = { type: 'pending_invitee', display_name: 'Pending Invitee', image: GuestIcon };
  if (user.type === 2) {
    if (user.roles.includes('zendesk')) {
      userType = { type: 'zendesk', display_name: 'Zendesk', image: Zendesk };
    } else if (user.roles.includes('team_user') && user.roles.includes('team_admin') && user.roles.includes('team_owner')) {
      userType = { type: 'team_owner', display_name: 'Team Owner', image: PrimaryOwnerIcon };
    } else if (user.roles.includes('team_user') && user.roles.includes('team_admin')) {
      userType = { type: 'team_admin', display_name: 'Team Admin', image: WorkspaceAmdinIcon };
    } else {
      userType = { type: 'team_user', display_name: 'Team User', image: MemberIcon };
    }
  }
  return userType;
}

export function countTeamAdminInTeams(teams) {
  return teams.filter((team) => (team.roles).includes('team_admin') || (team.roles).includes('team_owner'));
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function toCamel(s) {
  return s.replace(/([-_][a-z])/ig, ($1) => ($1.toUpperCase()
    .replace('-', '')
    .replace('_', '')));
}

export function toPascal(s) {
  return capitalizeFirstLetter(toCamel(s));
}

// Copyright 2019, White Label Communications, LLC, All rights reserved.

import React from 'react';
import LeftArrow from 'images/left-arrow.svg';
import PropTypes from 'prop-types';

const validate = (email) => email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

const StepTwo = ({
  handleInputChange,
  values,
  previousStep,
  nextStep,
}) => {
  const isValid = (values.adminEmail.length > 0 && validate(values.adminEmail));
  return (
    <>
      <div className="form-area step-two">
        <h1>
          To create your
          <span>NEW</span>
          Team, please enter your email address.
        </h1>
        <input
          type="email"
          className="email-address"
          placeholder="Your Email"
          name="adminEmail"
          onChange={handleInputChange}
          value={values.adminEmail} onKeyPress={event => {
                if (event.key === 'Enter') {
                  nextStep()
                }
              }}
        />
        <button type="button" className="back-button" onClick={previousStep}>
          <img src={LeftArrow} alt="boomea" />
          {' '}
        </button>
        <button disabled={!isValid} type="button" className="confirm-button" onClick={nextStep}>
          CONFIRM
          {' '}
        </button>
      </div>
    </>
  );
};

StepTwo.propTypes = {
  nextStep: PropTypes.func,
  previousStep: PropTypes.func,
  handleInputChange: PropTypes.func.isRequired,
  values: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.arrayOf(
      PropTypes.string,
    )]),
  ).isRequired,
};

StepTwo.defaultProps = {
  previousStep: () => {},
  nextStep: () => {},
};

export default StepTwo;

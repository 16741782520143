// Copyright 2019, White Label Communications, LLC, All rights reserved.

import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Media, Dropdown,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { imageURLForUser } from 'components/Common/Utils';
import SwitchTeam from 'components/Layout/Header/SwitchTeam';
import Logo from 'images/logo-dashboard.svg';
import MenuIcon from 'images/menu-icon.svg';

class DashBoardHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSwitchTeam: false,
    };
  }

  componentDidMount() {
    this.loadUserStatus();
    this.statusInterval = setInterval(this.loadUserStatus, 30000);
  }

  componentWillUnmount() {
    clearInterval(this.statusInterval);
    this.statusInterval = 0;
  }

  loadUserStatus = () => {
    const {
      usersStatus,
      login,
    } = this.props;
    if (login.loggedinUserId) {
      usersStatus([login.loggedinUserId]);
    }
  }

  toggleSidebar = () => {
    document.getElementById('root').classList.toggle('small-sidebar');
  }

  capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  handleSwitchTeam = (e) => {
    e.preventDefault();
    this.setState({ showSwitchTeam: true });
  }

  handleSwitchTeamCancel = () => {
    this.setState({ showSwitchTeam: false });
  }

  handleTeamChosen = (teamId) => {
    const {
      currentTeam,
      setCurrentTeamId,
    } = this.props;
    if (teamId !== currentTeam.currentTeamId) {
      setCurrentTeamId(teamId);
      setTimeout(() => {
        window.location.reload(); // after delay of 100 ms which is required to change team, reload the page after setting selected team as current team
      }, 100);
    }
  }

  render() {
    const {
      login,
      usersCompleteList,
      teams,
      currentTeam,
    } = this.props;
    const {
      showSwitchTeam,
    } = this.state;
    // console.log('complete Users List: ', usersCompleteList);
    return (
      <header
        className="header"
        id="header"
      >
        <Container fluid>
          <Row>
            <Col xs={8} md={9}>
              <div className="boomea-logo">
                <img src={Logo} alt="boomea" className="logo" />
              </div>
              <div className="menu-icon" role="presentation" onClick={this.toggleSidebar} onKeyDown={this.toggleSidebar}>
                <img src={MenuIcon} alt="boomea" />
              </div>
              <div className="welcome-text">
                <div>Welcome to Admin</div>
                <div className="team-name">
Team:&nbsp;
                  {(teams && teams[currentTeam.currentTeamId] && teams[currentTeam.currentTeamId].display_name)
                    ? teams[currentTeam.currentTeamId].display_name
                    : ' '}
                </div>
              </div>
            </Col>
            <Col xs={4} md={3}>
              <Dropdown alignRight variant="transparnt">
                <Dropdown.Toggle>
                  <Media className="avatar-section">
                    <Media.Body>
                      <div className="avatar-body">
                        <h4>
                          { usersCompleteList[login.loggedinUserId]
                            ? usersCompleteList[login.loggedinUserId].first_name + (
                              usersCompleteList[login.loggedinUserId].last_name
                                ? (` ${usersCompleteList[login.loggedinUserId].last_name}`)
                                : '')
                            : ' ' }
                        </h4>
                        <div className={`status ${usersCompleteList[login.loggedinUserId]
                          ? usersCompleteList[login.loggedinUserId].status
                          : ''}`}
                        >
                          <div className="badge" />
                          {' '}
                          {usersCompleteList[login.loggedinUserId]
                            ? this.capitalize(usersCompleteList[login.loggedinUserId].status)
                            : ' '}
                        </div>
                      </div>
                    </Media.Body>
                    <img
                      width={48}
                      height={48}
                      className="ml-2 rounded-circle avatar"
                      src={imageURLForUser(usersCompleteList[login.loggedinUserId])}
                      alt="Avatar"
                    />
                  </Media>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {teams && Object.keys(teams).length > 1
                    ? <Link className="dropdown-item" to="/switchTeam" onClick={this.handleSwitchTeam}>Switch Team</Link>
                    : null }
                  <Link className="dropdown-item" to="/logout">Logout</Link>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
        </Container>
        <SwitchTeam
          show={showSwitchTeam}
          onCancel={this.handleSwitchTeamCancel}
          teams={teams}
          handleTeamChosen={this.handleTeamChosen}
          currentTeam={currentTeam}
        />
      </header>
    );
  }
}

DashBoardHeader.propTypes = {
  setCurrentTeamId: PropTypes.func.isRequired,
  login: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  ).isRequired,
  usersCompleteList: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  ).isRequired,
  usersStatus: PropTypes.func.isRequired,
  currentTeam: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  ).isRequired,
  teams: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  ).isRequired,
};

export default DashBoardHeader;

// Copyright 2019, White Label Communications, LLC, All rights reserved.

import React from 'react';
import equal from 'deep-equal';
import PropTypes from 'prop-types';
import { Modal, Button, Form } from 'react-bootstrap';

class EditUser extends React.Component {
  constructor(props) {
    super(props);
    const { user } = this.props;
    this.state = {
      newUserEntry: {
        first_name: user.first_name ? user.first_name : '',
        last_name: user.last_name ? user.last_name : '',
        email: user.email ? user.email : '',
        account_type: user.account_type ? user.account_type : 2,
      },
      user,
    };
  }

  static getDerivedStateFromProps(props, state) {
    // Any time the current user changes,
    // Reset any parts of state that are tied to that user.
    if (!equal(props.user, state.user)) {
      return {
        user: props.user,
        newUserEntry: {
          first_name: props.user.first_name ? props.user.first_name : '',
          last_name: props.user.last_name ? props.user.last_name : '',
          email: props.user.email ? props.user.email : '',
          account_type: props.user.account_type ? props.user.account_type : 2,
        },
      };
    }
    return null;
  }

  handleInputField = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      ...prevState,
      newUserEntry: {
        ...prevState.newUserEntry,
        [name]: value,
      },
    }));
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { handleSubmit } = this.props;
    const { newUserEntry } = this.state;
    handleSubmit(newUserEntry);
  }

  render() {
    const {
      show,
      isEditForm,
      handleCloseEditUserForm,
    } = this.props;
    // console.log('isEditForm: ', isEditForm);
    const {
      newUserEntry,
    } = this.state;
    return (
      <Modal show={show} className="add-cduser" animation>
        <Form onSubmit={this.handleSubmit}>
          <Modal.Header>
            <Modal.Title>
              {isEditForm ? 'UPDATE USER' : 'ADD USER'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="cduser_FirstName">
              <Form.Label>
                First Name:
                {' '}
              </Form.Label>
              <Form.Control
                type="text"
                name="first_name"
                value={newUserEntry.first_name}
                onChange={this.handleInputField}
              />
            </Form.Group>
            <Form.Group controlId="cduser_LastName">
              <Form.Label>
                Last Name:
                {' '}
              </Form.Label>
              <Form.Control
                type="text"
                name="last_name"
                value={newUserEntry.last_name}
                onChange={this.handleInputField}
              />
            </Form.Group>
            <Form.Group controlId="cduser_UserEmail">
              <Form.Label>
                <span>*</span>
                Email:
                {' '}
              </Form.Label>
              {isEditForm
                ? (
                  <Form.Control
                    disabled
                    required
                    type="email"
                    name="email"
                    value={newUserEntry.email}
                    onChange={this.handleInputField}
                  />
                )
                : (
                  <Form.Control
                    required
                    type="email"
                    name="email"
                    value={newUserEntry.email}
                    onChange={this.handleInputField}
                  />
                )}
            </Form.Group>
            <Form.Group controlId="cduser_AccountType">
              <Form.Label>
                <span>*</span>
                Account Type:
                {' '}
              </Form.Label>
              <select
                required
                name="account_type"
                value={newUserEntry.account_type}
                onChange={this.handleInputField}
                className="form-control"
              >
                <option value="2"> Team User </option>
                <option value="1"> Team Admin </option>
              </select>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="button"
              variant="secondary"
              onClick={handleCloseEditUserForm}
            >
              Cancel
            </Button>
            <Button type="submit" variant="blue">
              {isEditForm ? 'Save' : 'Add'}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

EditUser.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleCloseEditUserForm: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  isEditForm: PropTypes.number.isRequired,
  user: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ),
};

EditUser.defaultProps = {
  user: {},
};

export default EditUser;

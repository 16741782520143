import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Container, Row, Col, Form, Button, Modal,
} from 'react-bootstrap';
import DashBoard from 'components/Layout/Dashboard';
import Layout from 'components/Layout/Layout';
import BillingIcon from 'images/money.svg';
import SuccessAlert from 'components/Common/Alerts/Success';
import ErrorAlert from 'components/Common/Alerts/Error';
import AsyncPaginate from 'react-select-async-paginate';
import equal from 'deep-equal';

const customStyles = {
  multiValue: (styles) => (
    {
      ...styles,
      backgroundColor: '#599ad1',
      borderRadius: '15px',
      height: '30px',
    }
  ),
  multiValueLabel: (styles) => ({
    ...styles,
    color: 'white',
    fontSize: '100%',
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: 'white',
    height: '30px',
    ':hover': {
      backgroundColor: data.color,
      color: 'white',
      cursor: 'pointer',
    },
  }),
};

class Triggers extends Component {
  constructor(props) {
    super(props);
    const { integrationDetail, defaultChannelsData, authentication } = this.props;
    let defaultIntegrationDetail = null;
    if (integrationDetail) {
      defaultIntegrationDetail = integrationDetail;
    }

    this.state = {
      successAlert: '',
      errorAlert: '',
      integrationDetail,
      defaultIntegrationDetail,
      show: false,
      switch1: false,
      defaultChannelsData,
      authentication,
    };
  }

  static getDerivedStateFromProps(props, state) {
    // Any time props value changes set the current state accordingly
    let newStateData = null;
    if (!equal(props.integrationDetail, state.defaultIntegrationDetail)) {
      const { integrationDetail, defaultChannelsData, authentication } = props;
      // console.log('constructor', defaultChannelsData); // eslint-disable-line no-console
      let defaultIntegrationDetail = null;
      if (integrationDetail) {
        defaultIntegrationDetail = integrationDetail;
      }
      const newState = {
        integrationDetail,
        defaultIntegrationDetail,
        defaultChannelsData,
        authentication,
        switch1: !authentication,
      };
      newStateData = { ...newStateData, ...newState };
    }
    return newStateData;
  }

  componentDidMount = () => {
    const { fetchIntegrationDetail, currentTeam, history } = this.props;
    const { state } = history.location;
    const { integrationId } = state;
    fetchIntegrationDetail(integrationId, currentTeam.currentTeamId);
  }

  componentDidUpdate(prevProps) {
    const { integration } = this.props;
    if (prevProps.integration.put.status === 1 && integration.put.status === 2) {
      this.setAlerts('', integration.put.error);
    }
    if (prevProps.integration.put.status === 1 && integration.put.status === 3) {
      this.setAlerts('Updated Successfully', '');
      // history.push('/dashboard/integrations');
    }
    if (prevProps.integration.deleteIntegration.status === 1 && integration.deleteIntegration.status === 2) {
      this.setAlerts('', integration.put.error);
    }
    if (prevProps.integration.deleteIntegration.status === 1 && integration.deleteIntegration.status === 3) {
      this.setAlerts('Disable Successfully', '');
      // history.push('/dashboard/integrations');
    }
  }

  companyDirectoryUser = () => {
    this.setState({
      show: true,
    });
  }

  setAlerts = (successAlert, errorAlert) => {
    this.setState({
      successAlert,
      errorAlert,
    });
  }

  onAlertDismissed = (alertType) => {
    this.setState({
      [alertType]: '',
    });
  }

  selectChannel = (option) => {
    if (option) {
      // const checkIfGenChnlExist = option.filter((channel) => (channel.label).toLowerCase() === 'general');
      this.setState({ defaultChannelsData: option });
      this.setState(
        (prevState) => ({
          integrationDetail: {
            ...prevState.integrationDetail,
            detail: {
              ...prevState.integrationDetail.detail,
              channels: option,
            },
          },
        }),
      );
    }
  }

  loadOptions = async (search, loadedOptions, { page, currentTeamId }) => {
    const { fetchAllChannels } = this.props;
    let channelSuggestions = [];
    const res = await fetchAllChannels(currentTeamId, page);
    if (!res || !res.data || !res.data.length) { // if not result or not result data or not result data length THEN Return
      /* channelSuggestions = [
        {
          value: '1',
          label: 'General',
        },
        {
          value: '2',
          label: 'General2',
        },
        {
          value: '3',
          label: 'General 3 General 3',
        },
        {
          value: '4',
          label: 'General 4 General 4',
        },
      ]; */
      return {
        options: channelSuggestions,
        hasMore: false,
        additional: {
          page,
        },
      };
    }
    // Transform the channels to what react-select expects
    channelSuggestions = res.data.map((channel) => ({ value: channel.id, label: channel.display_name }));
    let hasMore = false;
    if (channelSuggestions.length >= 100) {
      hasMore = true;
    }
    return {
      options: channelSuggestions,
      hasMore,
      additional: {
        page: hasMore ? page + 1 : page,
      },
    };
  }

  submitForm = (e) => {
    e.preventDefault();
    const { putSetting, currentTeam } = this.props;

    this.setState({
      successAlert: '',
      errorAlert: '',
    });

    const { integrationDetail, defaultChannelsData } = this.state;
    const { detail } = integrationDetail;
    if (!detail.ticket_created && !detail.ticket_updated) {
      this.setState({
        errorAlert: 'Please select atleast one action.',
      });
      return false;
    }
    const selectedChannel = [];
    defaultChannelsData.map((channel) => (
      selectedChannel.push(channel.value)
    ));
    const data = {
      channels: selectedChannel,
      ticket_created: detail.ticket_created,
      ticket_updated: detail.ticket_updated,
      message: detail.message,
      integration: detail.integration,
    };

    putSetting(currentTeam.currentTeamId, data);
    return true;
  }

  updateCredential = (e) => {
    e.preventDefault();
    const { putSetting, currentTeam } = this.props;

    this.setState({
      successAlert: '',
      errorAlert: '',
	  show: false
    });

    const { integrationDetail, authentication } = this.state;
    const { detail } = integrationDetail;
    const data = {
      domain: detail.domain,
      authentication: authentication ? 2 : 1,
      password: detail.password,
      token: detail.token,
      integration: detail.integration,
    };
    putSetting(currentTeam.currentTeamId, data);
  }

  toggleChange = (e) => {
    const { name } = e.target;
    this.setState(
      (prevState) => ({
        integrationDetail: {
          ...prevState.integrationDetail,
          detail: {
            ...prevState.integrationDetail.detail,
            [name]: !prevState.integrationDetail.detail[name],
          },
        },
      }),
    );
  }

  handleChangeField = (e) => {
    const { value } = e.target;
    this.setState(
      (prevState) => ({
        integrationDetail: {
          ...prevState.integrationDetail,
          detail: {
            ...prevState.integrationDetail.detail,
            message: btoa(value),
          },
        },
      }),
    );
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState(
      (prevState) => ({
        integrationDetail: {
          ...prevState.integrationDetail,
          detail: {
            ...prevState.integrationDetail.detail,
            [name]: value,
          },
        },
      }),
    );
  }

  handleClose = () => {
    this.setState({
      show: false,
    });
  }

  disableIntegration= (e) => {
    e.preventDefault();
    const { deleteSetting, currentTeam } = this.props;

    this.setState({
      successAlert: '',
      errorAlert: '',
    });
    const { integrationDetail } = this.state;
    const { detail } = integrationDetail;
    deleteSetting(currentTeam.currentTeamId, detail);
  }

  handleCredential= (e) => {
    e.preventDefault();
    this.setState({ show: true });
  }

  render() {
    const {
      successAlert,
      errorAlert,
      integrationDetail,
      show,
      authentication,
      switch1,
      defaultChannelsData,
    } = this.state;

    const { currentTeam } = this.props;
    const { detail } = integrationDetail;
    return (
      <Layout>
        <DashBoard>
          <div className="billing">
            <div className="title-section">
              <h2>
                <img src={BillingIcon} alt="boomea" />
                 Triggers
              </h2>
            </div>
            <div className="manage-users-table">
              <div className="billing-header">
                <Container fluid>
                  <Row>
                    <Col md={12}>Create Triggers</Col>
                  </Row>
                </Container>
              </div>
              <div className="manage-area">
                <Container fluid>
                  <ErrorAlert
                    error={errorAlert}
                    onAlertDismissed={this.onAlertDismissed}
                    alertType="errorAlert"
                  />
                  <SuccessAlert
                    success={successAlert}
                    onAlertDismissed={this.onAlertDismissed}
                    alertType="successAlert"
                  />
                  <Row>
                    <Col md={12}>
                      <Row>
                        <Col xs={12}>
                          <h3>Trigger detail</h3>
                        </Col>
                      </Row>
                      <Form onSubmit={this.submitForm} className="integrationForm">
                        <div className="form-box">
                          <div className="form-body">
                            <Row>
                              <Col xs={6}>
                                <Form.Group controlId="ticketCreated">
                                  <Form.Check
                                    type="checkbox"
                                    label="Ticket Created"
                                    name="ticket_created"
                                    checked={(detail && detail.ticket_created)
                                      ? detail.ticket_created
                                      : false}
                                    onChange={this.toggleChange}
                                  />
                                </Form.Group>
                                <Form.Group controlId="ticketUpdated">
                                  <Form.Check
                                    type="checkbox"
                                    label="Ticket Updated"
                                    name="ticket_updated"
                                    checked={(detail && detail.ticket_updated)
                                      ? detail.ticket_updated
                                      : false}
                                    onChange={this.toggleChange}
                                  />
                                </Form.Group>
                                <Form.Group controlId="exampleForm.ControlTextarea1">
                                  <Form.Label><h3>JSON body</h3></Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    className="messagetextarea"
                                    rows="100"
                                    value={(detail && detail.message)
                                      ? atob(detail.message)
                                      : null}
                                    onChange={this.handleChangeField}
                                  />
                                  <Form.Text className="text-muted">
                                    <h3>Placeholder</h3>
                                  </Form.Text>
                                </Form.Group>
                                <Form.Group controlId="exampleForm.ControlTextarea1">
                                  <Form.Label><h3>Channels</h3></Form.Label>
                                  <AsyncPaginate
                                    className="select-container"
                                    name="channels"
                                    onChange={this.selectChannel}
                                    value={(defaultChannelsData && defaultChannelsData)
                                      ? defaultChannelsData
                                      : []}
                                    loadOptions={this.loadOptions}
                                    isMulti
                                    isSearchable
                                    styles={customStyles}
                                    closeMenuOnSelect
                                    additional={{
                                      page: 0,
                                      currentTeamId: currentTeam.currentTeamId,
                                    }}
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                          </div>
                          <div className="form-footer">
                            <Row>
                              <Col xs={3}>
                                <Button variant="blue" type="submit">
                                  SAVE
                                </Button>
                              </Col>
                              <Col xs={3}>
                                <Button variant="blue" type="submit" onClick={this.disableIntegration}>
                                DISABLE
                                </Button>
                              </Col>
                              <Col xs={3}>
                                <Button variant="blue" type="submit" onClick={this.handleCredential}>
                                UPDATE CREDENTIAL
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </div>
          <Modal show={show} className="add-cduser" animation>
            <Form>
              <Modal.Header>
                <Modal.Title>UPDATE INTEGRATION CREDENTIALS</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group controlId="cduser_Name">
                  <Form.Label>
                    <span>*</span>
                        Domain
                    {' '}
                  </Form.Label>
                  <div className="form-group form-inline">
                    <Form.Control
                      type="text"
                      name="domain"
                      required
                      onChange={this.handleChange}
                      value={(detail && detail.domain)
                        ? detail.domain
                        : null}
                    />
                    &nbsp;.zendesk.com
                  </div>
                </Form.Group>
                <Form.Group controlId="cduser_Phone_Number">
                  <Form.Label>
                    <span>*</span>
                      Email
                    {' '}
                  </Form.Label>
                  <Form.Control
                    type="email"
                    name="username"
                    required
                    onChange={this.handleChange}
                    value={(detail && detail.username)
                      ? detail.username
                      : null}
                  />
                </Form.Group>
                <Form.Group controlId="password">
                   { /*<div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="customSwitches"
                      checked={switch1}
                      onChange={() => { this.setState({ authentication: switch1, switch1: !switch1 }); }}
                      readOnly
                    />
                    <label className="custom-control-label" htmlFor="customSwitches">
                      {(authentication
                        ? (
                          <div className="on">Enable</div>
                        ) : (
                          <div className="off">Disable</div>
                        )
                      )}
                      <div className="tokenlable">
                        <a
                          // eslint-disable-next-line no-bitwise
                          href="https://develop.zendesk.com/hc/en-us/articles/360001074508-Using-the-API-with-2-factor-authentication-enabled"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                              Token Authentication
                        </a>
                      </div>
                    </label>

                  </div> */ }
				  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="customSwitches"
                      checked={switch1}
                      onChange={() => { this.setState({ authentication: !switch1, switch1: !switch1 }); }}
                      readOnly
                    />
                    <label className="custom-control-label" htmlFor="customSwitches">
                      {(authentication
                        ? (
                          <div className="off">Disable</div>
                        ) : (
                          <div className="on">Enable</div>
                        )
                      )}
                      <div className="tokenlable">
                        <a
                          // eslint-disable-next-line no-bitwise
                          href="https://develop.zendesk.com/hc/en-us/articles/360001074508-Using-the-API-with-2-factor-authentication-enabled"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                              Token Authentication
                        </a>
                      </div>
                    </label>

                  </div>
                </Form.Group>
                {(!authentication
                  ? (
                    <Form.Group controlId="password">
                      <Form.Label>
                                      Password
                        <span>*</span>
                      </Form.Label>
                      <Form.Control type="password" name="password" required onChange={this.handleChange} />
                    </Form.Group>
                  )
                  : (
                    <Form.Group controlId="token">
                      <Form.Label>
                        <span>*</span>
                                      API Token
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows="6"
                        className="messagetextarea"
                        onChange={this.handleChange}
                        name="token"
                        required
                      />
                    </Form.Group>
                  )
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button type="button" variant="secondary" onClick={this.handleClose}>
                  Cancel
                </Button>
                <Button type="submit" variant="blue" onClick={this.updateCredential}>
                  Update
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        </DashBoard>
      </Layout>
    );
  }
}

Triggers.propTypes = {
  putSetting: PropTypes.func.isRequired,
  deleteSetting: PropTypes.func.isRequired,
  fetchAllChannels: PropTypes.func.isRequired,
  fetchIntegrationDetail: PropTypes.func.isRequired,
  history: PropTypes.objectOf().isRequired,
  location: PropTypes.objectOf().isRequired,
  integrationDetail: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.objectOf(
        PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
        ]),
      ),
    ]),
  ),
  authentication: PropTypes.bool.isRequired,
  defaultChannelsData: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  ).isRequired,
  currentTeam: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  ).isRequired,
  integration: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.objectOf(
        PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
        ]),
      ),
    ]),
  ).isRequired,
};

Triggers.defaultProps = {
  integrationDetail: { ticket_created: true },
};
export default Triggers;

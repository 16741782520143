// Copyright 2019, White Label Communications, LLC, All rights reserved.

import React from 'react';
import PropTypes from 'prop-types';
import { Media, Dropdown } from 'react-bootstrap';
import { imageURLForUser, getUserType } from 'components/Common/Utils';
// import Avatar from 'images/avatar.svg';
import DotsMenu from 'images/dots.svg';


const TeamList = ({
  user,
  showConfirmModal,
}) => {
  const userType = getUserType(user);

  return (
    <tr>
      <td>
        <Media>
          <img
            width={42}
            height={42}
            className="mr-3 rounded-circle"
            src={imageURLForUser(user)}
            alt=""
          />
          <Media.Body>
            <h5>
              {`${user.first_name} ${user.last_name}`}
              {' '}
            </h5>
            <p>{user.email}</p>
          </Media.Body>
        </Media>
      </td>
      <td>
        <div className="account-type">
          <img src={userType.image} alt={userType.display_name} />
          {' '}
          {userType.display_name}
        </div>
      </td>
      {userType.type === 'zendesk'
        ? <td className="dots-menu text-center" />
        : (
          <td className="dots-menu text-center">
            <Dropdown drop="left">
              <Dropdown.Toggle variant="primary" id="dropdown-basic">
                <img src={DotsMenu} alt="boomea" width={16} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  href="#/action-4"
                  onClick={(e) => showConfirmModal(e, user.id, 'force_logout')}
                >
                Force Logout
                </Dropdown.Item>
                <Dropdown.Item
                  href="#/action-5"
                  onClick={(e) => showConfirmModal(e, user.id, 'send_passwordreset')}
                >
                Send Reset Password
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </td>
        )}
    </tr>
  );
};

TeamList.propTypes = {
  user: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.objectOf(
        PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
        ]),
      ),
    ]),
  ).isRequired,
  showConfirmModal: PropTypes.func.isRequired,
};

export default TeamList;

// Copyright 2019, White Label Communications, LLC, All rights reserved.

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from 'PrivateRoute';
import PageHome from 'components/PageHome';
import PageLogin from 'components/PageLogin';
import PageLogout from 'components/PageLogout';
import UsersList from 'components/Users/Users/List';
import CompanyDirectory from 'components/Users/CompanyDirectory/List';
import FileRetention from 'components/TeamSettings/FileRetention';
import MessageRetention from 'components/TeamSettings/MessageRetention';
import DefaultChannels from 'components/TeamSettings/DefaultChannels';
import Billing from 'components/Billing';
import ActivityLog from 'components/Billing/ActivityLog';
import BillingHistory from 'components/Billing/BillingHistory';
import PaymentMethod from 'components/Billing/PaymentMethod';
import ChangeBoomeaPlan from 'components/Billing/ChangePlan';
import Files from 'components/Files';
import SignUp from 'components/SignUp';
import SignUpConfirm from 'components/SignUp/SignUpConfirm';
import NotesRetention from 'components/TeamSettings/NotesRetention';
import TasksRetention from 'components/TeamSettings/TasksRetention';
import MeetingRetention from 'components/TeamSettings/MeetingRetention';
import VoiceRetention from 'components/TeamSettings/VoiceRetention';
import Integrations from 'components/Integrations';
import Settings from 'components/Integrations/Settings';
import Triggers from 'components/Integrations/Triggers';
import PermissionError from 'components/PermissionError';
import PageAccessDenied from 'components/PageAccessDenied';


function Router() {
  return (
    <Switch>
      <PrivateRoute exact path="/" component={PageHome} />
      <Route path="/permission_error" component={PermissionError} />
      <Route path="/login" component={PageLogin} />
      <Route path="/logout" component={PageLogout} />
      <Route path="/signup" component={SignUp} />
      <Route path="/signup_confirm" component={SignUpConfirm} />
      <PrivateRoute path="/users/users" component={UsersList} />
      <PrivateRoute path="/users/company_directory" component={CompanyDirectory} />
      <PrivateRoute path="/dashboard/team_settings/file_retention" component={FileRetention} />
      <PrivateRoute path="/dashboard/team_settings/message_retention" component={MessageRetention} />
      <PrivateRoute path="/dashboard/team_settings/notes_retention" component={NotesRetention} />
      <PrivateRoute path="/dashboard/team_settings/tasks_retention" component={TasksRetention} />
      <PrivateRoute path="/dashboard/team_settings/meeting_retention" component={MeetingRetention} />
      <PrivateRoute path="/dashboard/team_settings/voice_retention" component={VoiceRetention} />
      <PrivateRoute path="/dashboard/team_settings/default_channels" component={DefaultChannels} />
      <PrivateRoute path="/dashboard/billing/settings" component={Billing} />
      <PrivateRoute path="/dashboard/billing/activity_log" component={ActivityLog} />
      <PrivateRoute path="/dashboard/billing/history" component={BillingHistory} />
      <PrivateRoute path="/dashboard/billing/payment_method" component={PaymentMethod} />
      <PrivateRoute path="/dashboard/billing/change_boomea_plan" component={ChangeBoomeaPlan} />
      <PrivateRoute path="/dashboard/files" component={Files} />
      <PrivateRoute path="/dashboard/integrations" component={Integrations} />
      <PrivateRoute path="/dashboard/settings/:integrationId" component={Settings} />
      <PrivateRoute path="/dashboard/triggers" component={Triggers} />
      <Route component={PageAccessDenied} />
    </Switch>
  );
}

export default Router;

// Copyright 2019, White Label Communications, LLC, All rights reserved.

import PropTypes from 'prop-types';
import React from 'react';
import {
  Alert,
} from 'react-bootstrap';

const SuccessAlert = (props) => {
  const {
    success,
    onAlertDismissed,
    alertType,
  } = props;
  if (!success) {
    return null;
  }

  return (
    <Alert variant="success" onClose={() => onAlertDismissed(alertType)} isOpen = {setInterval(()=>{
        onAlertDismissed(alertType)
      },4000)} dismissible>
      <Alert.Heading>Success!</Alert.Heading>
      <p>
        {success}
      </p>
    </Alert>
  );
};

SuccessAlert.propTypes = {
  success: PropTypes.string,
  onAlertDismissed: PropTypes.func.isRequired,
  alertType: PropTypes.string.isRequired,
};

SuccessAlert.defaultProps = {
  success: '',
};

export default SuccessAlert;

// Copyright 2019, White Label Communications, LLC, All rights reserved.

import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';

import LeftArrow from 'images/left-arrow.svg';
import AddMore from 'images/plus-round.svg';

class StepThree extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      teamEmails: { email1: '' },
    };
  }

  handleAddMoreEmails = () => {
    const { teamEmails } = this.state;
    const teamEmailsLength = Object.keys(teamEmails).length;
    const newTeamEmails = {
      ...teamEmails,
      [`email${teamEmailsLength + 1}`]: '',
    };
    this.setState({ teamEmails: newTeamEmails });
  };

  handleDeleteEmails = (element) => {
    const { teamEmails } = this.state;
    const newTeamEmails = { ...teamEmails };
    Reflect.deleteProperty(newTeamEmails, element); // remove that key element from teamEmails object
    this.setState({ teamEmails: newTeamEmails });
  };

  handleTeamEmailsChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      ...prevState,
      teamEmails: {
        ...prevState.teamEmails,
        [name]: value,
      },
    }));
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { nextStep, handleInputChange } = this.props;
    const { teamEmails } = this.state;
    const filteredTeamEmails = Object.keys(teamEmails)
      .filter((element) => teamEmails[element].length > 0)
      .map((selectedElement) => teamEmails[selectedElement]);
    handleInputChange(filteredTeamEmails);
    nextStep(); // go to next step if all validation passed
  };

  render() {
    const { previousStep, maxTeamEmails } = this.props;
    const { teamEmails } = this.state;
    return (
      <>
        <div className="form-area step-three">
          <h1>Who else is on your team?</h1>
          <form onSubmit={this.handleSubmit}>
            <PerfectScrollbar className="scroll-area">
              {Object.keys(teamEmails).map((element) => (
                <div className="content" key={element}>
                  <button
                    type="button"
                    className="add-more"
                    onClick={() => { this.handleDeleteEmails(element); }}
                    key={`button_${element}`}
                  >
                    {' '}
                    (-)
                    {' '}
                  </button>
                  <input
                    type="email"
                    key={element}
                    className="team-name email-address"
                    placeholder="Enter E-mail Address"
                    name={element}
                    value={teamEmails[element]}
                    onChange={this.handleTeamEmailsChange}
                  />
                </div>
              ))}
            </PerfectScrollbar>
            {Object.keys(teamEmails).length < maxTeamEmails ? (
              <button
                type="button"
                className="add-more"
                onClick={this.handleAddMoreEmails}
              >
                {' '}
                add more email
                <img src={AddMore} alt="boomea" />
                {' '}
              </button>
            ) : null}

            <button
              type="button"
              className="back-button"
              onClick={previousStep}
            >
              <img src={LeftArrow} alt="boomea" />
              {' '}
            </button>
            <button type="submit" className="entry-button">
              INVITE
              <br />
              TEAMMATES
            </button>
          </form>
        </div>
      </>
    );
  }
}

StepThree.propTypes = {
  maxTeamEmails: PropTypes.number.isRequired,
  nextStep: PropTypes.func,
  previousStep: PropTypes.func,
  handleInputChange: PropTypes.func.isRequired,
};

StepThree.defaultProps = {
  previousStep: () => {},
  nextStep: () => {},
};

export default StepThree;
